:root {
  --tp-font-family: sans-serif;
}

.tp-txtrv {
  --font-family: monospace;
}

#container canvas {
  touch-action: none;
}

@media (prefers-color-scheme: dark) {
  #container {
    background-color: rgb(39, 39, 39);
  }
}

@media (prefers-color-scheme: light) {
  #container {
    background-color: rgb(221, 221, 221);
  }

  :root {
    --tp-base-background-color: hsla(230, 5%, 90%, 1);
    --tp-base-shadow-color: hsla(0, 0%, 0%, 0.1);
    --tp-button-background-color: hsla(230, 7%, 75%, 1);
    --tp-button-background-color-active: hsla(230, 7%, 60%, 1);
    --tp-button-background-color-focus: hsla(230, 7%, 65%, 1);
    --tp-button-background-color-hover: hsla(230, 7%, 70%, 1);
    --tp-button-foreground-color: hsla(230, 10%, 30%, 1);
    --tp-container-background-color: hsla(230, 15%, 30%, 0.2);
    --tp-container-background-color-active: hsla(230, 15%, 30%, 0.32);
    --tp-container-background-color-focus: hsla(230, 15%, 30%, 0.28);
    --tp-container-background-color-hover: hsla(230, 15%, 30%, 0.24);
    --tp-container-foreground-color: hsla(230, 10%, 30%, 1);
    --tp-groove-foreground-color: hsla(230, 15%, 30%, 0.1);
    --tp-input-background-color: hsla(230, 15%, 30%, 0.1);
    --tp-input-background-color-active: hsla(230, 15%, 30%, 0.22);
    --tp-input-background-color-focus: hsla(230, 15%, 30%, 0.18);
    --tp-input-background-color-hover: hsla(230, 15%, 30%, 0.14);
    --tp-input-foreground-color: hsla(230, 10%, 30%, 1);
    --tp-label-foreground-color: hsla(230, 10%, 30%, 0.7);
    --tp-monitor-background-color: hsla(230, 15%, 30%, 0.1);
    --tp-monitor-foreground-color: hsla(230, 10%, 30%, 0.5);
  }
}

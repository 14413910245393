*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.25;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

address {
  font-style: inherit;
}

:root {
  --tp-font-family: sans-serif;
}

.tp-txtrv {
  --font-family: monospace;
}

#container canvas {
  touch-action: none;
}

@media (prefers-color-scheme: dark) {
  #container {
    background-color: #272727;
  }
}

@media (prefers-color-scheme: light) {
  #container {
    background-color: #ddd;
  }

  :root {
    --tp-base-background-color: #e4e5e7;
    --tp-base-shadow-color: #0000001a;
    --tp-button-background-color: #bbbcc4;
    --tp-button-background-color-active: #9294a0;
    --tp-button-background-color-focus: #a0a2ac;
    --tp-button-background-color-hover: #adafb8;
    --tp-button-foreground-color: #454754;
    --tp-container-background-color: #41455833;
    --tp-container-background-color-active: #41455852;
    --tp-container-background-color-focus: #41455847;
    --tp-container-background-color-hover: #4145583d;
    --tp-container-foreground-color: #454754;
    --tp-groove-foreground-color: #4145581a;
    --tp-input-background-color: #4145581a;
    --tp-input-background-color-active: #41455838;
    --tp-input-background-color-focus: #4145582e;
    --tp-input-background-color-hover: #41455824;
    --tp-input-foreground-color: #454754;
    --tp-label-foreground-color: #454754b3;
    --tp-monitor-background-color: #4145581a;
    --tp-monitor-foreground-color: #45475480;
  }
}

/*# sourceMappingURL=index.ccf71f7c.css.map */
